import React from "react";
import Typography from "@mui/material/Typography";
import FestivalIcon from "@mui/icons-material/Festival";
import Stack from "@mui/material/Stack";
import HelpPages from "./helpPages/HelpPages";

interface IOnboardingCta {
  onClick: () => void;
}

const OnboardingCta: React.FC<IOnboardingCta> = ({ onClick }) => {
  return (
    <Stack spacing={2}>
      <FestivalIcon sx={{ fontSize: 100, alignSelf: "center" }} />

      <Typography variant="body1" textAlign="center">
        You haven't signed up to any events yet
      </Typography>

      <Typography variant="body1" textAlign="center">
        Follow the instructions below to get started!
      </Typography>

      <HelpPages />
    </Stack>
  );
};

export default OnboardingCta;
