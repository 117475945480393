import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useMemo } from "react";
import { brand } from "./brand";
import { useColorMode } from "./hooks/useColorMode";
import { useReducedMotion } from "./hooks/useReducedMotion";

const primaryFontFamily = ["Univers", "sans-serif"].join(",");
const headerFontFamily = ["Goudy", "sans-serif"].join(",");

export const useTheme = () => {
  const { isDarkModeEnabled } = useColorMode();
  const { reducedMotion } = useReducedMotion();

  const theme = useMemo(() => {
    const t = createTheme({
      typography: {
        allVariants: {
          fontFamily: primaryFontFamily,
        },
        h1: {
          fontFamily: headerFontFamily,
        },
        h2: {
          fontFamily: headerFontFamily,
        },
        h3: {
          fontFamily: headerFontFamily,
        },
        h4: {
          fontFamily: headerFontFamily,
        },
        h5: {
          fontFamily: headerFontFamily,
        },
        h6: {
          fontFamily: headerFontFamily,
        },
        subtitle2: {
          color: brand.lightGrey,
        },
      },
      palette: {
        mode: isDarkModeEnabled ? "dark" : "light",
        primary: {
          main: brand.orange,
        },
        secondary: {
          main: brand.blue,
        },
        error: {
          main: brand.orange,
        },
        warning: {
          main: brand.yellow,
        },
        info: {
          main: brand.blue,
        },
        success: {
          main: brand.green,
        },
        ...(!isDarkModeEnabled && {
          background: {
            default: "#ffffff",
            paper: "#ffffff",
          },
          text: {
            primary: brand.darkGrey,
            secondary: `${brand.darkGrey}b3`,
            disabled: `${brand.darkGrey}80`,
          },
        }),
      },
      ...(reducedMotion
        ? {
            transitions: { create: () => "none" },
            components: {
              MuiButtonBase: { defaultProps: { disableTouchRipple: true } },
            },
          }
        : {}),
    });
    return responsiveFontSizes(t);
  }, [isDarkModeEnabled, reducedMotion]);

  return { theme };
};
