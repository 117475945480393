import React from "react";
import { getHexForColor } from "../oldTheme";
import styled from "styled-components";

interface StyledHeadingProps {
  color: string;
}

const StyledHeading = styled.div<StyledHeadingProps>`
  display: inline-block;
  box-sizing: border-box;
  border-bottom: double 9px;
  border-bottom-color: #${(props) => props.color};
  color: #${(props) => props.theme.palette.darkGrey};
`;

export interface HeadingProps {
  text: string;
  color: Color;
}

export const Heading: React.FC<HeadingProps> = ({ text, color }) => (
  <StyledHeading color={getHexForColor(color)}>
    <h1>{text}</h1>
  </StyledHeading>
);
