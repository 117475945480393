import {
  User,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  OAuthProvider,
  signInWithRedirect,
} from "firebase/auth";

const provider = new OAuthProvider("microsoft.com");

provider.setCustomParameters({
  tenant: "390c54b8-e374-4842-a54b-40de8f33588d",
});

export const sendVerificationEmail = async (user: User) => {
  await sendEmailVerification(user);
};

export const signUp = async (email: string, password: string) => {
  const auth = getAuth();
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  userCredential.user && (await sendVerificationEmail(userCredential.user));
};

export const signIn = async (email: string, password: string) => {
  const auth = getAuth();
  await signInWithEmailAndPassword(auth, email, password);
};

export const oAuthSignIn = () => {
  const auth = getAuth();
  signInWithRedirect(auth, provider);
};

export const signOut = async (onSignOut: () => void) => {
  if (window.confirm("Are you sure you want to sign out?")) {
    const auth = getAuth();
    await auth.signOut();
    onSignOut();
  }
};

export const forgotPassword = async (email: string) => {
  const auth = getAuth();
  await sendPasswordResetEmail(auth, email);
};

export const refreshToken = async (currentUser: User) => {
  await currentUser.getIdToken(true);
};
