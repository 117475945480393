import { EventType2 } from "./shared";

export const brand = {
  orange: "#F3924B",
  pink: "#CDADD1",
  green: "#D6DF39",
  blue: "#9EDBEC",
  yellow: "#FCEA8A",
  purpleComplementary: "#908FBE",
  yellowComplementary: "#FFF8DA",
  darkGrey: "#1a1a1a",
  lightGrey: "#aaaaaa",
};

export const getHexForEventType = (eventType: EventType2) => {
  switch (eventType) {
    case "Activity":
      return brand.green;
    case "Food":
      return brand.yellow;
    case "Music":
      return brand.blue;
    case "Special":
      return brand.orange;
    default:
      return brand.lightGrey;
  }
};
