import {
  ActionCodeSettings,
  getAuth,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { useState, useCallback, useContext } from "react";
import { SnackbarContext } from "../../../contexts/snackbarContext";

const useAuth = () => {
  const auth = getAuth();
  const { pushSnackbar } = useContext(SnackbarContext);

  const [isSigningInWithEmail, setIsSigningInWithEmail] = useState(false);
  const signInWithEmail = useCallback(
    async (email: string) => {
      setIsSigningInWithEmail(true);

      try {
        const actionCodeSettings: ActionCodeSettings = {
          url: window.location.origin,
          handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem("emailForSignIn", email);
        pushSnackbar({
          message: `Sign in link sent to ${email}`,
          severity: "success",
        });
      } catch (err: any) {
        pushSnackbar({
          message: err.message || "Unknown error",
          severity: "error",
        });
      } finally {
        setIsSigningInWithEmail(false);
      }
    },
    [auth, pushSnackbar]
  );

  return {
    isSigningInWithEmail,
    signInWithEmail,
  };
};
export default useAuth;
