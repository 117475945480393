import { LoadingButton } from "@mui/lab";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import React from "react";
import { ButtonTypeMap } from "@mui/material";
import { useBookings } from "../../api/hooks/airtable/useBookings";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

interface ICreateBookingButton {
  eventId: string;
  onCreateSuccess: () => void;
  text: string;
  disabled: boolean;
  variant: ButtonTypeMap["props"]["variant"];
  type: CreateBookingButtonType;
}

export enum CreateBookingButtonType {
  CALENDAR,
  TICKET,
}

const getIcon = (createBookingButtonType: CreateBookingButtonType) => {
  switch (createBookingButtonType) {
    case CreateBookingButtonType.TICKET:
      return <ShoppingCartIcon />;
    default:
      return <EventAvailableIcon />;
  }
};

const CreateBookingButton: React.FC<ICreateBookingButton> = ({
  eventId,
  onCreateSuccess,
  text,
  disabled,
  variant,
  type,
}) => {
  const { createBooking, isCreatingBooking } = useBookings();

  return (
    <LoadingButton
      disabled={disabled}
      loading={isCreatingBooking}
      variant={variant}
      endIcon={getIcon(type)}
      onClick={() => createBooking({ eventId }).then(onCreateSuccess)}
    >
      {text}
    </LoadingButton>
  );
};

export default CreateBookingButton;
