import React, { useState } from "react";
import { GridContainer } from "../components/layout/gridContainer";
import { Heading } from "../components/Heading";
import {
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import { GenericButton } from "../components/GenericButton";
import { getHexForEventType } from "../oldTheme";
import firstAidIcon from "../assets/mapIcons/firstAid.svg";
import foodIcon from "../assets/mapIcons/food.svg";
import showerIcon from "../assets/mapIcons/shower.svg";
import toiletIcon from "../assets/mapIcons/toilet.svg";
import waterIcon from "../assets/mapIcons/water.svg";
import bandTentIcon from "../assets/mapIcons/bandTent.svg";
import campIcon from "../assets/mapIcons/camp.svg";
import carParkIcon from "../assets/mapIcons/carPark.svg";
import chilloutTentIcon from "../assets/mapIcons/chilloutTent.svg";
import funFairIcon from "../assets/mapIcons/funFair.svg";
import inflatablesIcon from "../assets/mapIcons/inflatables.svg";
import treasureHuntIcon from "../assets/mapIcons/treasureHunt.svg";
import campFireIcon from "../assets/mapIcons/campFire.svg";
import gamesArenaIcon from "../assets/mapIcons/gamesArena.svg";
import chargingTentIcon from "../assets/mapIcons/chargingTent.svg";
import pamperTentIcon from "../assets/mapIcons/pamperTent.svg";
import surpriseMarqueeIcon from "../assets/mapIcons/surpriseMarquee.svg";
import productionTentIcon from "../assets/mapIcons/productionTent.svg";
import { MobileLogoHeader } from "../components/navigation/MobileLogoHeader";

const centrePoint: LatLngExpression = [52.24622537516853, 0.22635660327454174];

const siteBoundary: LatLngExpression[] = [
  [52.25311735578148, 0.22604673877089282],
  [52.251268496786615, 0.228856979061497],
  [52.25197128764457, 0.2302624564747576],
  [52.25020184362739, 0.23289153962430995],
  [52.24999822434676, 0.2326447764143482],
  [52.24689784109069, 0.22703359524126465],
  [52.246628517915426, 0.22755930816683537],
  [52.246464295664715, 0.22744129097946233],
  [52.24308585438522, 0.23318985095498634],
  [52.242915049595155, 0.23086169371317297],
  [52.24099673518728, 0.23164489849068176],
  [52.23981417131969, 0.22625902289172606],
  [52.23979446165521, 0.22599080201133281],
  [52.240142664439254, 0.22501447800670138],
  [52.24118725640952, 0.22283652431023615],
  [52.241758815187545, 0.22206404817470357],
  [52.24197561141902, 0.22205331933948783],
  [52.24053685298333, 0.22531488524506976],
  [52.24175224560283, 0.22806146729066498],
  [52.241765384800466, 0.2275679408707414],
  [52.242159558920626, 0.22647359967873695],
  [52.2426259937732, 0.22586205607144036],
  [52.243545710141966, 0.22551873321542942],
  [52.243526002131084, 0.2226004898570129],
  [52.24295446611426, 0.2223000824709724],
  [52.24337490711833, 0.22092679156335904],
  [52.24639670968577, 0.21941402557685488],
  [52.25133571424497, 0.2256806865943505],
  [52.251388259661326, 0.22538027920831005],
  [52.252268386133146, 0.22415719199371686],
];

interface Area {
  name: string;
  eventType: EventType;
  perimeter: LatLngExpression[];
}

const areas: Area[] = [
  {
    name: "Party camp",
    eventType: "sleep",
    perimeter: [
      [52.2509949733227, 0.2254823463639449],
      [52.250475243236746, 0.22660199319844126],
      [52.24922858192607, 0.22488660165842728],
      [52.249746362817075, 0.22385814993037986],
    ],
  },
  {
    name: "Quiet camp",
    eventType: "sleep",
    perimeter: [
      [52.2470697056807, 0.2271336247186858],
      [52.248246189130934, 0.22520890777074947],
      [52.24880028162053, 0.22602879145211346],
      [52.24756945667315, 0.2281086691133368],
    ],
  },
  {
    name: "Family camp",
    eventType: "sleep",
    perimeter: [
      [52.24790945667315, 0.2276586691133368],
      [52.24860028162053, 0.22645879145211346],
      [52.24890871999591, 0.22700135824776315],
      [52.24815871999591, 0.22815135824776315],
    ],
  },
  {
    name: "Car park",
    eventType: "special",
    perimeter: [
      [52.251660742536966, 0.23061088736090455],
      [52.25066120880138, 0.23208377096618606],
      [52.25018352168271, 0.23125076963906998],
      [52.25112722997243, 0.2297296062715507],
    ],
  },
  {
    name: "Games arena",
    eventType: "activity",
    perimeter: [
      [52.24880583130426, 0.22255533077619796],
      [52.24605751053757, 0.2269100159866298],
      [52.24358391369971, 0.2254418008544539],
      [52.24358391369971, 0.2225418008544539],
      [52.24300391369971, 0.2222818008544539],
      [52.24340391369971, 0.2209818008544539],
      [52.24639312380336, 0.21950067488552433],
    ],
  },
];

interface Landmark {
  name: string;
  eventType: EventType;
  position: LatLngExpression;
  icon: string;
}

const landmarks: Landmark[] = [
  {
    name: "Car Park",
    eventType: "special",
    position: [52.250926079777545, 0.23082172098861138],
    icon: carParkIcon,
  },
  {
    name: "Camp Fires",
    eventType: "special",
    position: [52.250926079777545, 0.22652172098861138],
    icon: campFireIcon,
  },
  {
    name: "Party Camp",
    eventType: "sleep",
    position: [52.250026079777545, 0.22532172098861138],
    icon: campIcon,
  },
  {
    name: "Quiet Camp",
    eventType: "sleep",
    position: [52.247946079777545, 0.22672172098861138],
    icon: campIcon,
  },
  {
    name: "Family Camp",
    eventType: "sleep",
    position: [52.24838945667315, 0.2273586691133368],
    icon: campIcon,
  },
  {
    name: "Toilets (Party Camp)",
    eventType: "special",
    position: [52.249386079777545, 0.22442172098861138],
    icon: toiletIcon,
  },
  {
    name: "Toilets (Quiet Camp)",
    eventType: "special",
    position: [52.248162994778585, 0.22701157777322932],
    icon: toiletIcon,
  },
  {
    name: "Toilets (Central)",
    eventType: "special",
    position: [52.2484319062359, 0.2246642940284815],
    icon: toiletIcon,
  },
  {
    name: "First Aid Station",
    eventType: "special",
    position: [52.248630248328715, 0.22505022832164348],
    icon: firstAidIcon,
  },
  {
    name: "Showers",
    eventType: "special",
    position: [52.24884725830824, 0.22543251492980193],
    icon: showerIcon,
  },
  {
    name: "Food Trucks",
    eventType: "food",
    position: [52.24817319062359, 0.2243242940284815],
    icon: foodIcon,
  },
  {
    name: "Water",
    eventType: "food",
    position: [52.24797319062359, 0.2240042940284815],
    icon: waterIcon,
  },
  {
    name: "Games Arena",
    eventType: "activity",
    position: [52.24577319062359, 0.2230642940284815],
    icon: gamesArenaIcon,
  },
  {
    name: "Treasure Hunt",
    eventType: "activity",
    position: [52.24397319062359, 0.2210642940284815],
    icon: treasureHuntIcon,
  },
  {
    name: "Fun Fair",
    eventType: "activity",
    position: [52.249056079777545, 0.22382172098861138],
    icon: funFairIcon,
  },
  {
    name: "Band Tent",
    eventType: "music",
    position: [52.248656079777545, 0.22332172098861138],
    icon: bandTentIcon,
  },
  {
    name: "Production Tent",
    eventType: "special",
    position: [52.248256079777545, 0.22372172098861138],
    icon: productionTentIcon,
  },
  {
    name: "Chillout Tent",
    eventType: "special",
    position: [52.247956079777545, 0.22482172098861138],
    icon: chilloutTentIcon,
  },
  {
    name: "Charging Tent",
    eventType: "sleep",
    position: [52.247856079777545, 0.22532172098861138],
    icon: chargingTentIcon,
  },
  {
    name: "Pamper Tent",
    eventType: "sleep",
    position: [52.247656079777545, 0.22482172098861138],
    icon: pamperTentIcon,
  },
  {
    name: "Surprise Marquee",
    eventType: "activity",
    position: [52.247556079777545, 0.22552172098861138],
    icon: surpriseMarqueeIcon,
  },
  {
    name: "Inflatable Zone",
    eventType: "activity",
    position: [52.247156079777545, 0.22632172098861138],
    icon: inflatablesIcon,
  },
];

const startWatchingPosition = (
  onSuccess: (pos?: GeolocationPosition) => void
) => {
  navigator.geolocation.watchPosition(onSuccess, (err) => {
    onSuccess(undefined);
    alert("Failed to get your location");
  });
};

export const MapPage: React.FC = () => {
  const [currentPosition, setCurrentPosition] = useState<
    GeolocationPosition | undefined
  >(undefined);

  return (
    <>
      <MobileLogoHeader />
      <GridContainer>
        <Heading text="Map" color="pink" />

        <p>
          Use this map to navigate around the Glasonbury site. You can also use
          the wayfinder signs.
        </p>

        <p>
          Some landmarks aren't shown on this map to add #hype. Use the
          wayfinder signs to find them.
        </p>

        <GenericButton
          onClick={() => startWatchingPosition(setCurrentPosition)}
        >
          Locate me
        </GenericButton>

        <MapContainer
          center={centrePoint}
          zoom={15}
          scrollWheelZoom={true}
          style={{ height: "600px" }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {!!currentPosition ? (
            <Marker
              position={[
                currentPosition.coords.latitude,
                currentPosition.coords.longitude,
              ]}
            />
          ) : null}

          <Polygon positions={siteBoundary} color="black" fillOpacity={0} />

          {areas.map((area) => (
            <Polygon
              positions={area.perimeter}
              color={`#${getHexForEventType(area.eventType)}`}
            />
          ))}

          {landmarks.map((landmark) => {
            const icon = new L.Icon({
              iconUrl: landmark.icon,
              iconRetinaUrl: landmark.icon,
              iconSize: new L.Point(40, 40),
            });

            return (
              <Marker position={landmark.position} icon={icon}>
                <Tooltip>{landmark.name}</Tooltip>
              </Marker>
            );
          })}
        </MapContainer>
      </GridContainer>
    </>
  );
};
