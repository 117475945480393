import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import logo from "../assets/logo.svg";
import Fade from "@mui/material/Fade";
import { oAuthSignIn } from "../firebase";
import { useContext, useState } from "react";
import { AuthContext } from "../contexts/authContext";
import CircularProgress from "@mui/material/CircularProgress";
import SignInDialog from "../dialogs/SignInDialog";

const LoginPage = () => {
  const { isLoadingAuth } = useContext(AuthContext);
  const [signInDialogIsOpen, setSignInDialogIsOpen] = useState(false);

  return (
    <>
      <Container>
        <Fade in={true} timeout={1000}>
          <Stack
            height="100dvh"
            maxWidth={600}
            marginX="auto"
            justifyContent="center"
            gap={6}
          >
            <img src={logo} alt="Logo" />

            {isLoadingAuth ? (
              <CircularProgress sx={{ alignSelf: "center" }} />
            ) : (
              <Stack gap={3}>
                <Button onClick={oAuthSignIn} variant="contained">
                  Employee sign in
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setSignInDialogIsOpen(true)}
                >
                  Guest sign in
                </Button>
              </Stack>
            )}
          </Stack>
        </Fade>
      </Container>

      <SignInDialog
        open={signInDialogIsOpen}
        onClose={() => setSignInDialogIsOpen(false)}
      />
    </>
  );
};

export default LoginPage;
