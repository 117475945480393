import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import useGuests from "../../api/hooks/firebase/useGuests";
import LoadingButton from "@mui/lab/LoadingButton";

interface INewGuestForm {
  onClose: () => void;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .test(
      "is-not-softwire",
      (d) => `You can't use an @softwire.com email address`,
      (value) => !value?.endsWith("@softwire.com") || false
    )

    .required("This field is required"),
  username: Yup.string()
    .max(50, "Must be 50 characters or less")
    .required("This field is required"),
});

export const NewGuestForm: React.FC<INewGuestForm> = ({ onClose }) => {
  const { createGuest } = useGuests();

  const formik = useFormik({
    initialValues: { email: "", username: "" },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await createGuest(values.email, values.username);
        onClose();
      } catch (err) {
        alert(err.message);
      }

      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack gap={3}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          id="username"
          name="username"
          label="Name"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
        <LoadingButton
          color="secondary"
          variant="contained"
          type="submit"
          endIcon={<SendIcon />}
          loading={formik.isSubmitting}
          sx={{
            alignSelf: "flex-end",
          }}
        >
          Submit
        </LoadingButton>
      </Stack>
    </form>
  );
};
