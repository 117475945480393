import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ListIcon from "@mui/icons-material/List";
import Paper from "@mui/material/Paper";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

const NavBar = () => {
  const { pathname } = useLocation();

  const pages = [
    {
      path: "/",
      label: "Home",
      icon: <HomeIcon />,
    },
    {
      path: "/events",
      label: "Events",
      icon: <ListIcon />,
    },
    {
      path: "/settings",
      label: "Settings",
      icon: <SettingsIcon />,
    },
  ];

  const pageValue = pages.map((page) => page.path).indexOf(pathname);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation showLabels value={pageValue}>
        {pages.map((page) => (
          <BottomNavigationAction
            key={page.path}
            component={Link}
            to={page.path}
            label={page.label}
            icon={page.icon}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default NavBar;
