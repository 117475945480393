import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

interface IPageWrapper {
  title: string;
  children: React.ReactFragment;
}

export const PageWrapper: React.FC<IPageWrapper> = ({ title, children }) => {
  return (
    <Stack spacing={3}>
      <Typography variant="h2">{title}</Typography>
      <Box>{children}</Box>
    </Stack>
  );
};
