import React from "react";
import HelpPage1Events from "./HelpPage1Events";
import HelpPage2Guests from "./HelpPage2Guests";
import HelpPage3Home from "./HelpPage3Home";
import HelpPage4Install from "./HelpPage4Install";
import GenericTabs from "../GenericTabs";

const HelpPages: React.FC = () => {
  return (
    <GenericTabs
      label="getting-started"
      ariaLabel="getting started tabs"
      tabs={[
        {
          label: "Events",
          content: <HelpPage1Events />,
        },
        {
          label: "Guests",
          content: <HelpPage2Guests />,
        },
        {
          label: "Home",
          content: <HelpPage3Home />,
        },
        {
          label: "Install",
          content: <HelpPage4Install />,
        },
      ]}
    />
  );
};

export default HelpPages;
