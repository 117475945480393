import React, { useEffect, useState } from "react";
import BaseDialog from "./BaseDialog";
import { Booking } from "../shared";
import Stack from "@mui/material/Stack";
import ListItem from "../components/ListItem";
import Typography from "@mui/material/Typography";
import { useBookings } from "../api/hooks/airtable/useBookings";
import LinearProgress from "@mui/material/LinearProgress";

interface IAttendeeListDialog {
  isOpen: boolean;
  eventId?: string;
  onClose: () => void;
}

const AttendeeListDialog: React.FC<IAttendeeListDialog> = ({
  isOpen,
  eventId,
  onClose,
}) => {
  const { getBookings, isLoadingGetBookings } = useBookings();

  const [bookings, setBookings] = useState<Booking[]>([]);

  useEffect(() => {
    if (isOpen && eventId) {
      getBookings({ eventId }).then((bookings) => setBookings(bookings || []));
    } else {
      setBookings([]);
    }
  }, [isOpen, eventId, getBookings]);

  return (
    <BaseDialog
      open={isOpen && !!eventId}
      onClose={onClose}
      title="Attendees"
      maxWidth="xs"
      content={
        isLoadingGetBookings ? (
          <LinearProgress />
        ) : (
          <Stack spacing={1}>
            {bookings.length > 0 ? (
              bookings.map((booking) => (
                <ListItem
                  // @ts-ignore
                  key={booking.id}
                  text={booking.attendee.username}
                />
              ))
            ) : (
              <Typography variant="body1">No attendees</Typography>
            )}
          </Stack>
        )
      }
    />
  );
};

export default AttendeeListDialog;
