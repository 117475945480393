import { useCallback, useContext, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { SnackbarContext } from "../../../contexts/snackbarContext";
import { getApp } from "firebase/app";
import { FunctionResponse } from "./airtable";
import { Event } from "../../../shared";

const useEvents = () => {
  const { pushSnackbar } = useContext(SnackbarContext);
  const functions = getFunctions(getApp(), "europe-west2");

  const [isLoadingGetEvents, setIsLoadingGetEvents] = useState(false);
  const getEvents = useCallback(async () => {
    setIsLoadingGetEvents(true);

    const callable = httpsCallable<undefined, FunctionResponse<Event>>(
      functions,
      "airtable-events-getEvents"
    );

    try {
      const response = await callable();
      return response.data.data;
    } catch (err: any) {
      // TODO - Type this better than 'any'
      pushSnackbar({
        message: err.message || "Unknown error",
        severity: "error",
      });
    } finally {
      setIsLoadingGetEvents(false);
    }
  }, [functions, pushSnackbar]);

  const [isLoadingGetFeaturedEvents, setIsLoadingGetFeaturedEvents] =
    useState(false);
  const getFeaturedEvents = useCallback(async () => {
    setIsLoadingGetFeaturedEvents(true);

    const callable = httpsCallable<undefined, FunctionResponse<Event>>(
      functions,
      "airtable-events-getFeaturedEvents"
    );

    try {
      const response = await callable();
      return response.data.data;
    } catch (err: any) {
      // TODO - Type this better than 'any'
      pushSnackbar({
        message: err.message || "Unknown error",
        severity: "error",
      });
    } finally {
      setIsLoadingGetFeaturedEvents(false);
    }
  }, [functions, pushSnackbar]);

  const [isLoadingGetEvent, setIsLoadingGetEvent] = useState(false);
  const getEvent = useCallback(
    async (eventId: string) => {
      setIsLoadingGetEvent(true);

      const callable = httpsCallable<
        { eventId: string },
        FunctionResponse<Event>
      >(functions, "airtable-events-getEvent");
      try {
        const response = await callable({ eventId });
        return response.data.data;
      } catch (err: any) {
        // TODO - Type this better than 'any'
        pushSnackbar({
          message: err.message || "Unknown error",
          severity: "error",
        });
      } finally {
        setIsLoadingGetEvent(false);
      }
    },
    [functions, pushSnackbar]
  );

  return {
    isLoadingGetEvents,
    getEvents,
    isLoadingGetFeaturedEvents,
    getFeaturedEvents,
    isLoadingGetEvent,
    getEvent,
  };
};

export default useEvents;
