import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

export enum ColorMode {
  auto = "auto",
  light = "light",
  dark = "dark",
}

export const useColorMode = () => {
  const isOperatingSystemDarkMode = useMediaQuery(
    "(prefers-color-scheme: dark)"
  );

  const [colorMode, setColorMode] = useLocalStorage(
    "preferenceColorMode",
    ColorMode.light
  );

  const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(false);
  useEffect(() => {
    switch (colorMode) {
      case "auto":
        setIsDarkModeEnabled(isOperatingSystemDarkMode);
        break;
      case "dark":
        setIsDarkModeEnabled(true);
        break;
      case "light":
      default:
        setIsDarkModeEnabled(false);
        break;
    }
  }, [colorMode, isOperatingSystemDarkMode]);

  return { colorMode, setColorMode, isDarkModeEnabled };
};
