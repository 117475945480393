import Snackbar from "@mui/material/Snackbar";
import React from "react";
import Alert, { AlertColor } from "@mui/material/Alert";

interface SnackbarMessage {
  message: string;
  severity: AlertColor;
}

interface KeyedSnackbarMessage extends SnackbarMessage {
  key: number;
}

interface ISnackbarContext {
  pushSnackbar: (snackbarMessage: SnackbarMessage) => void;
}

export const SnackbarContext = React.createContext<ISnackbarContext>({
  pushSnackbar: () => {},
});

export const SnackbarProvider: React.FC = ({ children }) => {
  const [snackPack, setSnackPack] = React.useState<
    readonly KeyedSnackbarMessage[]
  >([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<
    KeyedSnackbarMessage | undefined
  >(undefined);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const pushSnackbar = React.useCallback((snackbarMessage: SnackbarMessage) => {
    setSnackPack((prev) => [
      ...prev,
      { ...snackbarMessage, key: new Date().getTime() },
    ]);
  }, []);

  return (
    <SnackbarContext.Provider value={{ pushSnackbar: pushSnackbar }}>
      {children}

      <Snackbar
        key={messageInfo?.key}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
      >
        {/* TODO - Get this value from the theme */}
        <Alert
          sx={{ position: "fixed", bottom: 70 }}
          severity={messageInfo?.severity}
        >
          {" "}
          {messageInfo?.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
