import BaseDialog from "./BaseDialog";
import { NewGuestForm } from "../components/forms/NewGuestForm";
import Typography from "@mui/material/Typography";

interface INewGuestDialog {
  open: boolean;
  onClose: () => void;
}

const NewGuestDialog: React.FC<INewGuestDialog> = ({ open, onClose }) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={"Add a guest"}
      content={
        <>
          <Typography variant="body1">
            Add your guest to the guest list.
          </Typography>
          <NewGuestForm onClose={onClose} />
        </>
      }
    />
  );
};

export default NewGuestDialog;
