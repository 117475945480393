import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
  User,
} from "firebase/auth";
import { SnackbarContext } from "./snackbarContext";
import { refreshToken } from "../firebase";

const isUserValid = (user: User): boolean => {
  return (
    user.emailVerified &&
    user.email != null &&
    user.email.endsWith("@softwire.com")
  );
};

interface IAuthContext {
  isSignedIn: boolean;
  userIsValid: boolean;
  currentUser?: User;
  isLoadingAuth: boolean;
  isUserSoftwire: () => boolean;
}

export const AuthContext = React.createContext<IAuthContext>({
  isSignedIn: false,
  userIsValid: false,
  currentUser: undefined,
  isLoadingAuth: true,
  isUserSoftwire: () => false,
});

const extractErrorMessage = (errorMessage: string) => {
  try {
    const regExp = /\(\([^{]*(\{.*})[^}]*\)\)/;
    const regExpArray = regExp.exec(errorMessage);
    if (!regExpArray) return errorMessage;
    const errorJson = JSON.parse(regExpArray[1]);
    return `${errorJson.error.status} - ${errorJson.error.message}`;
  } catch (err) {
    return errorMessage;
  }
};

export const AuthProvider: React.FC = (props) => {
  const { pushSnackbar } = useContext(SnackbarContext);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userIsValid, setUserIsValid] = useState(false);
  const [currentUser, setCurrentUser] = useState<User>();
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);

  const auth = getAuth();

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      signInWithEmailLink(auth, email || "", window.location.href)
        .then((result) => {
          window.localStorage.removeItem("emailForSignIn");
        })
        .catch((error) => {
          const errorMessage = extractErrorMessage(error.message);
          pushSnackbar({
            message: errorMessage || "Unknown error",
            severity: "error",
          });
        });
    }
  }, [auth, pushSnackbar]);

  useEffect(() => {
    if (isSignedIn) {
      pushSnackbar({
        message: "Signed in",
        severity: "success",
      });
    } else if (!isSignedIn && !isLoadingAuth) {
      pushSnackbar({
        message: "Signed out",
        severity: "success",
      });
    }
  }, [isLoadingAuth, isSignedIn, pushSnackbar]);

  const getNewTokenDelayed = useCallback((user: User) => {
    setTimeout(() => {
      user.getIdTokenResult().then((idToken) => {
        if (idToken.claims.airtableId) {
          return;
        } else {
          refreshToken(user).then(() => getNewTokenDelayed(user));
        }
      });
    }, 1000);
  }, []);

  useEffect(() => {
    if (currentUser) getNewTokenDelayed(currentUser);
  }, [currentUser, getNewTokenDelayed]);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setIsSignedIn(true);
      setUserIsValid(isUserValid(user));
      setCurrentUser(user);
    } else {
      setIsSignedIn(false);
      setUserIsValid(false);
      setCurrentUser(undefined);
    }
    setIsLoadingAuth(false);
  });

  const isUserSoftwire = () => {
    return currentUser?.providerData[0].providerId === "microsoft.com";
  };

  return (
    <AuthContext.Provider
      value={{
        userIsValid,
        currentUser,
        isSignedIn,
        isLoadingAuth,
        isUserSoftwire,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
