import React, { useCallback, useContext, useEffect, useState } from "react";

import { PageWrapper } from "../components/PageWrapper";
import Box from "@mui/material/Box";

import LinearProgress from "@mui/material/LinearProgress";
import {
  Alert,
  AlertTitle,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import useEvents from "../api/hooks/airtable/useEvents";
import { Booking, Event } from "../shared";
import Stack from "@mui/material/Stack";
import { useBookings } from "../api/hooks/airtable/useBookings";
import EventDays from "../components/events/EventDays";
import { EventDialogContext } from "../contexts/eventDialogContext";

const eventTypes = ["Activity", "Food", "Music", "Special"];

export const EventsPage: React.FC = () => {
  const [events, setEvents] = useState<Event[] | undefined>(undefined);
  const [myBookings, setMyBookings] = useState<Booking[] | undefined>(
    undefined
  );

  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>([]);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedEventTypes>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedEventTypes(typeof value === "string" ? value.split(",") : value);
  };

  const { getBookings } = useBookings();
  const { getEvents, isLoadingGetEvents } = useEvents();

  const refreshMyBookings = useCallback(() => {
    getBookings({ onlyMyBookings: true }).then(setMyBookings);
  }, [getBookings]);

  useEffect(() => {
    getEvents().then(setEvents);
    refreshMyBookings();
  }, [getEvents, refreshMyBookings]);

  const filteredEvents =
    events?.filter(
      (evt) =>
        selectedEventTypes.length === 0 || selectedEventTypes.includes(evt.type)
    ) || [];

  const myBookingEventIds = new Set(
    myBookings?.map((myBooking) => myBooking.event.id) || []
  );

  const { eventId, setEventId } = useContext(EventDialogContext);

  useEffect(() => {
    if (!eventId) refreshMyBookings();
  }, [eventId, refreshMyBookings]);

  const [ticketedBookingCount, setTicketedBookingCount] = useState(0);
  useEffect(() => {
    let ticketedBookings: { [key: string]: Booking } = {};
    myBookings?.forEach((booking) => {
      if (!booking.event.requiresSignUp) return;
      ticketedBookings[booking.event.id] = booking;
    });

    setTicketedBookingCount(Object.keys(ticketedBookings).length);
  }, [myBookings]);

  const tooManyTicketedBookings = ticketedBookingCount > 1;

  return (
    <>
      <PageWrapper title="Events">
        {isLoadingGetEvents || !events ? (
          <LinearProgress />
        ) : (
          <Stack spacing={2}>
            {/*<Alert*/}
            {/*  severity={tooManyTicketedBookings ? "error" : "info"}*/}
            {/*  variant={tooManyTicketedBookings ? "filled" : "standard"}*/}
            {/*>*/}
            {/*  <AlertTitle>*/}
            {/*    You've signed up for <b>{ticketedBookingCount}</b> ticketed*/}
            {/*    event{ticketedBookingCount !== 1 && "s"}*/}
            {/*  </AlertTitle>*/}
            {/*  {tooManyTicketedBookings &&*/}
            {/*    "We ask that you only sign up for 1 ticketed event to give everyone a chance to sign up."}*/}
            {/*</Alert>*/}

            <FormControl sx={{ width: 300 }}>
              <InputLabel id="activity-filter-chips-label">
                Event types
              </InputLabel>
              <Select
                labelId="activity-filter-chips-label"
                id="demo-activity-filter-chips"
                multiple
                value={selectedEventTypes}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    id="select-activity-filter-chips"
                    label="Event types"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {eventTypes.map((eventType) => (
                  <MenuItem key={eventType} value={eventType}>
                    {eventType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <EventDays
              events={filteredEvents}
              myBookingEventIds={myBookingEventIds}
              onClickEvent={(event) => {
                // @ts-ignore
                setEventId(event.id);
              }}
            />
          </Stack>
        )}
      </PageWrapper>
    </>
  );
};
