import SellIcon from "@mui/icons-material/Sell";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { useBookings } from "../../api/hooks/airtable/useBookings";
import { ButtonTypeMap } from "@mui/material";
import EventBusyIcon from "@mui/icons-material/EventBusy";

interface IDeleteBookingButton {
  bookingId: string;
  onDeleteSuccess: () => void;
  text: string;
  variant: ButtonTypeMap["props"]["variant"];
  type: DeleteBookingButtonType;
}

export enum DeleteBookingButtonType {
  CALENDAR,
  TICKET,
}

const getIcon = (deleteBookingButtonType: DeleteBookingButtonType) => {
  switch (deleteBookingButtonType) {
    case DeleteBookingButtonType.TICKET:
      return <SellIcon />;
    default:
      return <EventBusyIcon />;
  }
};

const DeleteBookingButton: React.FC<IDeleteBookingButton> = ({
  bookingId,
  onDeleteSuccess,
  text,
  variant,
  type,
}) => {
  const { deleteBooking, isDeletingBooking } = useBookings();

  return (
    <LoadingButton
      loading={isDeletingBooking}
      variant={variant}
      endIcon={getIcon(type)}
      onClick={() => deleteBooking({ bookingId }).then(onDeleteSuccess)}
    >
      {text}
    </LoadingButton>
  );
};

export default DeleteBookingButton;
