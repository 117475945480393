import React from "react";
import { Event } from "../../shared";
import _ from "lodash";
import EventHour from "./EventHour";
import Stack from "@mui/material/Stack";

interface IEventDay {
  events: Event[];
  myBookingEventIds: Set<string>;
  onClickEvent: (event: Event) => void;
}

const EventDay: React.FC<IEventDay> = ({
  events,
  myBookingEventIds,
  onClickEvent,
}) => {
  const hours = _(events)
    .groupBy((evt) => new Date(evt.startTime).getHours())
    .map((hourEvents, key) => ({ key, hourEvents }))
    .value();

  return (
    <Stack spacing={2}>
      {hours.map(({ key, hourEvents }) => (
        <EventHour
          key={key}
          events={hourEvents}
          myBookingEventIds={myBookingEventIds}
          onClickEvent={onClickEvent}
        />
      ))}
    </Stack>
  );
};

export default EventDay;
