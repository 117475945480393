import React from "react";
import { Event } from "../../shared";
import _ from "lodash";
import GenericTabs from "../GenericTabs";
import EventDay from "./EventDay";
import { formatDay } from "../../utils";

interface IEventDays {
  events: Event[];
  myBookingEventIds: Set<string>;
  onClickEvent: (event: Event) => void;
}

const EventDays: React.FC<IEventDays> = ({
  events,
  myBookingEventIds,
  onClickEvent,
}) => {
  const days = _(events)
    .groupBy((evt) => new Date(evt.startTime).getDate())
    .map((dayEvents, key) => ({ key, dayEvents }))
    .value();

  const tabs = days.map((day) => ({
    label: formatDay(new Date(day.dayEvents[0].startTime)),
    content: (
      <EventDay
        events={day.dayEvents}
        myBookingEventIds={myBookingEventIds}
        onClickEvent={onClickEvent}
      />
    ),
  }));

  return (
    <GenericTabs label="event-days" ariaLabel="Event days tabs" tabs={tabs} />
  );
};

export default EventDays;
