import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import useGuests from "../api/hooks/firebase/useGuests";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";

interface IDeleteButton {
  guestEmail: string;
}

const DeleteButton: React.FC<IDeleteButton> = ({ guestEmail }) => {
  const { isDeletingGuest, deleteGuest } = useGuests();

  return (
    <IconButton
      edge="end"
      aria-label="delete"
      onClick={() => deleteGuest(guestEmail)}
      disabled={isDeletingGuest}
    >
      {/* TODO - Get 24 value from the theme */}
      {isDeletingGuest ? <CircularProgress size={24} /> : <DeleteIcon />}
    </IconButton>
  );
};

const GuestsList = () => {
  const { isLoadingGuests, guests } = useGuests();

  if (isLoadingGuests) return <LinearProgress />;

  if (guests.length === 0)
    return (
      <Typography variant="body1" color="text.secondary">
        You have no guests
      </Typography>
    );

  return (
    <List dense={true}>
      {guests.map((guest) => (
        <ListItem
          key={guest.email}
          secondaryAction={<DeleteButton guestEmail={guest.email} />}
        >
          <ListItemText
            primary={`${guest.username} - ${guest.email}`}
            secondary={`Added: ${guest.createdAt?.toDate().toLocaleString()}`}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default GuestsList;
