import React from "react";
import styled from "styled-components";

const StyledGridContainer = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.measurements.xlgSpacing}px;
  max-width: ${(props) => props.theme.screens.desktopMin}px;
  margin: 0 auto;
  padding: ${(props) => props.theme.measurements.lgSpacing}px;
  padding-top: ${(props) => props.theme.measurements.xxlgSpacing}px;
`;

export const GridContainer: React.FC = (props) => {
  return <StyledGridContainer>{props.children}</StyledGridContainer>;
};
