import React from "react";
import { ThemeProvider as OldThemeProvider } from "styled-components";
import { theme as oldTheme } from "../oldTheme";
import { getApp, initializeApp } from "firebase/app";
import { AuthProvider } from "./authContext";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "../theme";
import { SnackbarProvider } from "./snackbarContext";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { EventDialogProvider } from "./eventDialogContext";

const firebaseConfig = {
  apiKey: "AIzaSyDTCw758WnC4tLCHGuxL_tGZYTTRiJ0p9U",
  authDomain: "glasonbury.softwire.com",
  projectId: "glasonbury",
  storageBucket: "glasonbury.appspot.com",
  messagingSenderId: "941676346373",
  appId: "1:941676346373:web:67e5d39136964fd0c2706c",
  databaseURL: "",
};

initializeApp(firebaseConfig);

if (process.env.NODE_ENV === "development") {
  const auth = getAuth();
  connectAuthEmulator(auth, `http://${window.location.hostname}:9099`);

  const db = getFirestore();
  connectFirestoreEmulator(db, window.location.hostname, 8080);

  const functions = getFunctions(getApp(), "europe-west2");
  connectFunctionsEmulator(functions, window.location.hostname, 5001);
}

export const ProviderCollection: React.FC = (props) => {
  const { theme } = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <AuthProvider>
          <EventDialogProvider>
            <OldThemeProvider theme={oldTheme}>
              {props.children}
            </OldThemeProvider>
          </EventDialogProvider>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
