import { TimeFormat } from "./enums";

const getOptions = (timeFormat: TimeFormat): Intl.DateTimeFormatOptions => {
  const shortTime: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  switch (timeFormat) {
    case TimeFormat.SHORT_TIME:
      return shortTime;
    case TimeFormat.LONG_TIME:
      return {
        weekday: "long",
        month: "short",
        day: "numeric",
        ...shortTime,
      };
    case TimeFormat.LONG_DAY:
      return {
        weekday: "long",
        month: "long",
        day: "numeric",
        timeZone: "America/Los_Angeles",
      };
    case TimeFormat.SHORT_DAY:
    default:
      return {};
  }
};

export const getFormattedDateTime = (
  epochSeconds: number,
  timeFormat: TimeFormat
) => {
  return Intl.DateTimeFormat("en", getOptions(timeFormat)).format(
    new Date(epochSeconds * 1000)
  );
};

export const groupEventsIntoDays = (
  events: EventModel[]
): Map<string, EventModel[]> => {
  return events.reduce((map, currentEvent) => {
    const dateKey = getFormattedDateTime(
      currentEvent.startTime,
      TimeFormat.LONG_DAY
    );
    map.set(
      dateKey,
      map.get(dateKey) ? map.get(dateKey).concat(currentEvent) : [currentEvent]
    );
    return map;
  }, new Map());
};

export const formatDay = (date: Date): string => {
  return new Intl.DateTimeFormat("en-GB", {
    weekday: "long",
    day: "numeric",
    timeZone: "Europe/London",
  }).format(date);
};

export const formatDayTime = (date: Date): string => {
  return new Intl.DateTimeFormat("en-GB", {
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    timeZone: "Europe/London",
  }).format(date);
};

export const formatTime = (date: Date): string => {
  return new Intl.DateTimeFormat("en-GB", {
    hour: "numeric",
    minute: "numeric",
    timeZone: "Europe/London",
  }).format(date);
};

export const formatCurrency = (num: number): string => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(num);
};
