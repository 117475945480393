import {
  getFirestore,
  onSnapshot,
  query,
  collection,
  where,
  DocumentData,
  doc,
  setDoc,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/authContext";
import { SnackbarContext } from "../../../contexts/snackbarContext";

const useGuests = () => {
  const authContext = useContext(AuthContext);
  const { pushSnackbar } = useContext(SnackbarContext);
  const db = getFirestore();

  const [isLoadingGuests, setIsLoadingGuests] = useState(false);
  const [guests, setGuests] = useState<any[]>([]);
  useEffect(() => {
    setIsLoadingGuests(true);
    const db = getFirestore();
    return onSnapshot(
      query(
        collection(db, "guests"),
        // TODO - Add withConverter function
        where("employeeId", "==", authContext.currentUser?.uid || "")
      ),
      (querySnapshot) => {
        const newGuests: ((prevState: never[]) => never[]) | DocumentData[] =
          [];
        querySnapshot.forEach((doc) => newGuests.push(doc.data()));
        setGuests(newGuests);
        setIsLoadingGuests(false);
      },
      (error) => {
        setGuests([]);
        pushSnackbar({ message: error.message, severity: "error" });
      }
    );
  }, [authContext.currentUser?.uid, pushSnackbar]);

  const [isCreatingGuest, setIsCreatingGuest] = useState(false);
  const createGuest = useCallback(
    async (email: string, username: string) => {
      setIsCreatingGuest(true);

      const guestEmail = email.toLowerCase();

      try {
        await setDoc(doc(db, "guests", guestEmail), {
          email: guestEmail,
          employeeId: authContext.currentUser?.uid,
          createdAt: serverTimestamp(),
          username: username,
        });
        pushSnackbar({
          message: `Added guest ${guestEmail}`,
          severity: "success",
        });
      } catch (err: any) {
        pushSnackbar({
          message: err.mesage || "Unknown error",
          severity: "error",
        });
      } finally {
        setIsCreatingGuest(false);
      }
    },
    [authContext.currentUser?.uid, db, pushSnackbar]
  );

  const [isDeletingGuest, setIsDeletingGuest] = useState(false);
  const deleteGuest = useCallback(
    async (email: string) => {
      setIsDeletingGuest(true);

      try {
        await deleteDoc(doc(db, "guests", email));
        pushSnackbar({
          message: `Deleted guest ${email}`,
          severity: "success",
        });
      } catch (err: any) {
        pushSnackbar({
          message: err.mesage || "Unknown error",
          severity: "error",
        });
      } finally {
        setIsDeletingGuest(false);
      }
    },
    [db, pushSnackbar]
  );

  return {
    isLoadingGuests,
    guests,
    isCreatingGuest,
    createGuest,
    isDeletingGuest,
    deleteGuest,
  };
};

export default useGuests;
