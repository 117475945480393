import React from "react";
import Paper from "@mui/material/Paper";
import { Stack, Typography } from "@mui/material";

interface IListItem {
  text: string;
  rightButton?: JSX.Element;
}

const ListItem: React.FC<IListItem> = ({ text, rightButton }) => {
  return (
    <Paper sx={{ padding: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">{text}</Typography>
        {rightButton}
      </Stack>
    </Paper>
  );
};

export default ListItem;
