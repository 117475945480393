export const theme = {
  palette: {
    red: "ff0000",
    pink: "ff33cc",
    green: "00b050",
    blue: "00b0f0",
    orange: "ff9933",
    darkGrey: "1a1a1a",
    lightGrey: "aaaaaa",
  },
  measurements: {
    padding: 10,
    smSpacing: 5,
    mdSpacing: 10,
    lgSpacing: 20,
    xlgSpacing: 40,
    xxlgSpacing: 80,
  },
  screens: {
    desktopMin: 950,
  },
  timings: {
    menuTransition: 250,
  },
};

export const getHexForColor = (color: Color): string => {
  switch (color) {
    case "red":
      return theme.palette.red;
    case "pink":
      return theme.palette.pink;
    case "green":
      return theme.palette.green;
    case "blue":
      return theme.palette.blue;
    case "orange":
      return theme.palette.orange;
    case "darkGrey":
      return theme.palette.darkGrey;
    case "lightGrey":
      return theme.palette.lightGrey;
    default:
      return theme.palette.darkGrey;
  }
};

export const getHexForEventType = (eventType: EventType): string => {
  switch (eventType) {
    case "music":
      return theme.palette.pink;
    case "activity":
      return theme.palette.green;
    case "food":
      return theme.palette.orange;
    case "special":
      return theme.palette.red;
    case "sleep":
      return theme.palette.blue;
    default:
      return theme.palette.darkGrey;
  }
};
