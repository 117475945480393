import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const HelpPage4Install: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h5" component="h2">
        Install
      </Typography>
      <Typography variant="body1">
        You can{" "}
        <Typography component="b" fontWeight="bold">
          download
        </Typography>{" "}
        the Glasonbury App to your device so that it appears on your home
        screen.
      </Typography>
      <Typography variant="body1">
        Installation steps differ from browser to browser. You can find some
        general steps{" "}
        <Typography
          component="a"
          color="primary"
          href="https://www.bitcot.com/how-to-install-a-pwa-to-your-device/"
          target="_blank"
        >
          here
        </Typography>
        .
      </Typography>
    </Stack>
  );
};

export default HelpPage4Install;
