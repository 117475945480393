import React from "react";
import EventCard from "./EventCard";
import Box from "@mui/material/Box";
import { Event } from "../../shared";

interface IEventCards {
  events: Event[];
  myBookingEventIds: Set<string>;
  onClickEvent: (event: Event) => void;
}

const EventCards: React.FC<IEventCards> = ({
  events,
  myBookingEventIds,
  onClickEvent,
}) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
      gap={1}
    >
      {events.map((evt) => (
        <EventCard
          // @ts-ignore
          key={evt.id}
          event={evt}
          myBookingEventIds={myBookingEventIds}
          onClick={onClickEvent}
        />
      ))}
    </Box>
  );
};

export default EventCards;
