import { Avatar, Card, CardActionArea } from "@mui/material";
import React, { useContext } from "react";
import { Event } from "../../shared";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { formatDayTime } from "../../utils";
import { EventDialogContext } from "../../contexts/eventDialogContext";
import Stack from "@mui/material/Stack";

interface IFeaturedEventCard {
  event: Event;
}

const FeaturedEventCard: React.FC<IFeaturedEventCard> = ({ event }) => {
  const { setEventId } = useContext(EventDialogContext);
  return (
    <Card>
      <CardActionArea
        onClick={() => {
          // @ts-ignore
          setEventId(event.id);
        }}
      >
        <CardContent>
          <Stack direction="row" spacing={2}>
            <Avatar
              alt={`${event.name} thumbnail`}
              src={
                event.image?.thumbnails?.large.url ||
                event.venue.image.thumbnails?.large.url
              }
              sx={{ width: 75, height: 75 }}
              variant="rounded"
            />

            <Stack>
              <Typography component="h4" variant="h6">
                {event.name}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                {formatDayTime(new Date(event.startTime))}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default FeaturedEventCard;
