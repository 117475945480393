import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback, useContext, useState } from "react";
import { FunctionResponse } from "./airtable";
import { SnackbarContext } from "../../../contexts/snackbarContext";
import {
  Booking,
  CreateBookingParams,
  DeleteBookingParams,
  GetBookingsParams,
} from "../../../shared";

export const useBookings = () => {
  const { pushSnackbar } = useContext(SnackbarContext);
  const functions = getFunctions(getApp(), "europe-west2");

  const [isLoadingGetBookings, setIsLoadingGetBookings] = useState(false);
  const getBookings = useCallback(
    async (requestData: GetBookingsParams = {}) => {
      setIsLoadingGetBookings(true);

      const callable = httpsCallable<
        GetBookingsParams,
        FunctionResponse<Booking>
      >(functions, "airtable-bookings-getBookings");

      try {
        const response = await callable(requestData);
        return response.data.data;
      } catch (err: any) {
        // TODO - Type this better than 'any'
        pushSnackbar({
          message: err.message || "Unknown error",
          severity: "error",
        });
      } finally {
        setIsLoadingGetBookings(false);
      }
    },
    [functions, pushSnackbar]
  );

  const [isCreatingBooking, setIsCreatingBooking] = useState(false);
  const createBooking = useCallback(
    async (requestData: CreateBookingParams) => {
      setIsCreatingBooking(true);

      const callable = httpsCallable<
        CreateBookingParams,
        FunctionResponse<undefined>
      >(functions, "airtable-bookings-createBooking");

      try {
        await callable(requestData);
      } catch (err: any) {
        pushSnackbar({
          message: err.message || "Unknown error",
          severity: "error",
        });
      } finally {
        setIsCreatingBooking(false);
      }
    },
    [functions, pushSnackbar]
  );

  const [isDeletingBooking, setIsDeletingBooking] = useState(false);
  const deleteBooking = useCallback(
    async (requestData: DeleteBookingParams) => {
      setIsDeletingBooking(true);

      const callable = httpsCallable<
        DeleteBookingParams,
        FunctionResponse<undefined>
      >(functions, "airtable-bookings-deleteBooking");

      try {
        await callable(requestData);
      } catch (err: any) {
        pushSnackbar({
          message: err.message || "Unknown error",
          severity: "error",
        });
      } finally {
        setIsDeletingBooking(false);
      }
    },
    [functions, pushSnackbar]
  );

  return {
    isLoadingGetBookings,
    getBookings,
    isCreatingBooking,
    createBooking,
    isDeletingBooking,
    deleteBooking,
  };
};
