import React, { createContext, useCallback, useState } from "react";
import EventDialog from "../dialogs/EventDialog/EventDialog";

interface IEventDialogContext {
  eventId?: string;
  setEventId: (eventId: string) => void;
}

export const EventDialogContext = createContext<IEventDialogContext>({
  eventId: undefined,
  setEventId: () => {},
});

export const EventDialogProvider: React.FC = ({ children }) => {
  const [eventId, setEventId] = useState<string | undefined>(undefined);

  const setEventIdCallback = useCallback((eventIda: string) => {
    setEventId(eventIda);
  }, []);

  return (
    <EventDialogContext.Provider
      value={{ eventId, setEventId: setEventIdCallback }}
    >
      {children}

      <EventDialog
        eventId={eventId}
        onClose={() => {
          setEventId(undefined);
        }}
      />
    </EventDialogContext.Provider>
  );
};
