import React, { useEffect, useState } from "react";
import { useBookings } from "../api/hooks/airtable/useBookings";
import { PageWrapper } from "../components/PageWrapper";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { Booking } from "../shared";
import OnboardingCta from "../components/OnboardingCta";
import Stack from "@mui/material/Stack";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpDialog from "../dialogs/HelpDialog";
import Paper from "@mui/material/Paper";
import { GridProps } from "@mui/material/Grid/Grid";
import FeaturedEvents from "../components/featuredEvents/FeaturedEvents";
import EventsCalendar from "../components/EventsCalendar";

const GridItemPaper: React.FC<Omit<GridProps, "item">> = ({
  children,
  ...props
}) => {
  return (
    <Grid item {...props}>
      <Paper sx={{ padding: 3 }}>{children}</Paper>
    </Grid>
  );
};

export const HomePage: React.FC = () => {
  const [bookings, setBookings] = useState<Booking[] | undefined>(undefined);
  const [isHelpCtaOpen, setIsHelpCtaOpen] = useState(false);

  const openHelpCta = () => {
    setIsHelpCtaOpen(true);
  };

  const { getBookings, isLoadingGetBookings } = useBookings();

  useEffect(() => {
    getBookings({ onlyMyBookings: true }).then(setBookings);
  }, [getBookings]);

  // De-duplicate events
  const events =
    bookings &&
    bookings
      .filter(
        (v, i, a) => a.findIndex((v2) => v2.event.id === v.event.id) === i
      )
      .map(({ event }) => event);

  return (
    <>
      <PageWrapper title="Home">
        <Grid container spacing={2}>
          <GridItemPaper xs={12} md={8}>
            <Stack spacing={2}>
              <Typography variant="h3">Your itinerary</Typography>

              {isLoadingGetBookings || !bookings ? (
                <LinearProgress />
              ) : events && events.length > 0 ? (
                <EventsCalendar bookingEvents={events} />
              ) : (
                <OnboardingCta onClick={openHelpCta} />
              )}
            </Stack>
          </GridItemPaper>

          <GridItemPaper xs={12} md={4}>
            <Stack spacing={2}>
              <Typography variant="h3">Featured</Typography>
              <FeaturedEvents />
            </Stack>
          </GridItemPaper>

          <GridItemPaper xs={12}>
            <Button startIcon={<HelpOutlineIcon />} onClick={openHelpCta}>
              Help
            </Button>
          </GridItemPaper>
        </Grid>
      </PageWrapper>

      <HelpDialog
        isOpen={isHelpCtaOpen}
        onClose={() => setIsHelpCtaOpen(false)}
      />
    </>
  );
};
