import React from "react";
import { getHexForEventType } from "../../brand";
import { CardActionArea, Chip, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { formatTime } from "../../utils";
import Card from "@mui/material/Card";
import { Event } from "../../shared";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface IEventCard {
  event: Event;
  myBookingEventIds?: Set<string>;
  onClick: (event: Event) => void;
}

const EventCard: React.FC<IEventCard> = ({
  event,
  myBookingEventIds = new Set(),
  onClick,
}) => {
  const startTime = new Date(event.startTime);
  const endTime = new Date(event.endTime);
  // @ts-ignore
  const durationMins = Math.floor((endTime - startTime) / 1000 / 60);

  const isSoldOut = event.tickets.remaining === 0;

  return (
    <Card
      key={`${event.name}-${event.startTime}`}
      sx={
        // @ts-ignore
        myBookingEventIds.has(event.id)
          ? {
              border: `${getHexForEventType(event.type)} 5px solid`,
            }
          : {
              borderLeft: `${getHexForEventType(event.type)} 5px solid`,
            }
      }
    >
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        onClick={() => onClick(event)}
      >
        <CardContent sx={{ flex: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Stack spacing={1}>
                <Typography variant="subtitle1">
                  {formatTime(startTime)}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  {/* TODO - Use a theme colour for this */}
                  <AccessTimeIcon fontSize="small" color="disabled" />
                  <Typography variant="subtitle2">
                    {durationMins} mins
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack spacing={1}>
                <Typography variant="h6" component="h3">
                  {event.name}
                </Typography>
                <Typography>{event.venue.name}</Typography>
                <Stack spacing={1} direction="row">
                  {event.requiresSignUp && (
                    <Chip
                      label={isSoldOut ? "Sold out" : "Ticketed"}
                      variant="outlined"
                      color={isSoldOut ? "secondary" : "primary"}
                    />
                  )}

                  {event.tags.map((tag) => (
                    <Chip key={tag} label={tag} variant="outlined" />
                  ))}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default EventCard;
