import { useLocalStorage } from "usehooks-ts";

export const useReducedMotion = () => {
  const [reducedMotion, setReducedMotion] = useLocalStorage(
    "preferenceReducedMotion",
    false
  );

  return {
    reducedMotion,
    setReducedMotion,
  };
};
