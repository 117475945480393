import React from "react";
import { Event } from "../../shared";
import Typography from "@mui/material/Typography";
import { formatTime } from "../../utils";
import EventCards from "./EventCards";

interface IEventHour {
  events: Event[];
  myBookingEventIds: Set<string>;
  onClickEvent: (event: Event) => void;
}

const EventHour: React.FC<IEventHour> = ({
  events,
  myBookingEventIds,
  onClickEvent,
}) => {
  const startHourBlock = new Date(events[0].startTime);
  startHourBlock.setMinutes(0);

  const endHourBlock = new Date(startHourBlock);
  endHourBlock.setHours(endHourBlock.getHours() + 1);

  return (
    <>
      <Typography variant="h6" component="h3">
        {formatTime(startHourBlock)} - {formatTime(endHourBlock)}
      </Typography>
      <EventCards
        events={events}
        myBookingEventIds={myBookingEventIds}
        onClickEvent={onClickEvent}
      />
    </>
  );
};

export default EventHour;
