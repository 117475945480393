import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const HelpPage2Guests: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h5" component="h2">
        Guests
      </Typography>
      <Typography variant="body1">
        Guests can sign in with their own email address, but must be added to
        the{" "}
        <Typography component="b" fontWeight="bold">
          guest list
        </Typography>{" "}
        before doing so.
      </Typography>
      <Typography variant="body1">
        To add a guest, go to the{" "}
        <Typography component="b" fontWeight="bold">
          Settings
        </Typography>{" "}
        page and click{" "}
        <Typography component="b" fontWeight="bold">
          Add guest
        </Typography>
        . Enter your guest's email address and a username.
      </Typography>
      <Typography variant="body1">
        Once added, your guest will be able to sign in with their email address.
      </Typography>
      <Typography variant="body1">
        <Typography component="b" fontWeight="bold">
          Warning:
        </Typography>{" "}
        Your guest's username will be visible to everyone using the Glasonbury
        app. Their email address will be visible to app admins.
      </Typography>
    </Stack>
  );
};

export default HelpPage2Guests;
