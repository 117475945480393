import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { signOut } from "../firebase";
import GuestsList from "../components/GuestsList";
import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/authContext";
import { PageWrapper } from "../components/PageWrapper";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LogoutIcon from "@mui/icons-material/Logout";
import NewGuestDialog from "../dialogs/NewGuestDialog";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from "@mui/material";
import { ColorMode, useColorMode } from "../hooks/useColorMode";
import { useReducedMotion } from "../hooks/useReducedMotion";

const SettingsPage = () => {
  const navigate = useNavigate();
  const { isUserSoftwire, currentUser } = useContext(AuthContext);
  const [newGuestDialogIsOpen, setNewGuestDialogIsOpen] = useState(false);
  const { colorMode, setColorMode } = useColorMode();
  const { reducedMotion, setReducedMotion } = useReducedMotion();

  return (
    <>
      <PageWrapper title="Settings">
        <Stack spacing={3}>
          <Typography variant="body1">{currentUser?.email}</Typography>
          {isUserSoftwire() && (
            <>
              <Typography variant="h3">Your guests</Typography>
              <Typography variant="body1">
                Guests you add will be able to create their own Glasonbury
                account.
              </Typography>
              <GuestsList />
              <Button
                onClick={() => setNewGuestDialogIsOpen(true)}
                variant="contained"
                color="secondary"
                endIcon={<PersonAddIcon />}
                sx={{ alignSelf: "flex-start" }}
              >
                Add guest
              </Button>
            </>
          )}

          <Typography variant="h3">Appearance</Typography>
          <FormControl>
            <FormLabel id="color-mode-label">Color mode</FormLabel>
            <RadioGroup
              row
              aria-labelledby="color-mode-label"
              name="color-mode-radio-buttons-group"
              value={colorMode}
              onChange={(_, colorMode) => setColorMode(colorMode as ColorMode)}
            >
              <FormControlLabel
                value={ColorMode.auto}
                control={<Radio />}
                label="Auto"
              />
              <FormControlLabel
                value={ColorMode.light}
                control={<Radio />}
                label="Light"
              />
              <FormControlLabel
                value={ColorMode.dark}
                control={<Radio />}
                label="Dark"
              />
            </RadioGroup>
          </FormControl>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={reducedMotion}
                  onChange={(_, checked) => setReducedMotion(checked)}
                />
              }
              label="Reduced motion"
            />
          </FormGroup>

          <Typography variant="h3">Account</Typography>
          <Button
            onClick={() => signOut(() => navigate("/"))}
            variant="contained"
            endIcon={<LogoutIcon />}
            sx={{ alignSelf: "flex-start" }}
          >
            Sign out
          </Button>

          <Typography variant="body2" color="text.secondary">
            v{process.env.REACT_APP_VERSION}
          </Typography>
        </Stack>
      </PageWrapper>

      <NewGuestDialog
        open={newGuestDialogIsOpen}
        onClose={() => setNewGuestDialogIsOpen(false)}
      />
    </>
  );
};

export default SettingsPage;
