import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

interface TabContent {
  label: string;
  content: JSX.Element;
}

interface IGenericTabs {
  label: string;
  ariaLabel: string;
  tabs: TabContent[];
}

interface ITabPanelProps {
  label: string;
  index: number;
  value: number;
  children?: React.ReactNode;
}

const TabPanel: React.FC<ITabPanelProps> = ({
  label,
  index,
  value,
  children,
  ...other
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`${label}-tab-panel-${index}`}
      aria-labelledby={`${label}-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};

const a11yProps = (label: string, index: number) => {
  return {
    id: `${label}-tab-${index}`,
    "aria-controls": `${label}-tab-panel-${index}`,
  };
};

const GenericTabs: React.FC<IGenericTabs> = ({ label, ariaLabel, tabs }) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Stack spacing={2}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={currentTab}
          onChange={(_, newTab) => setCurrentTab(newTab)}
          aria-label={ariaLabel}
          scrollButtons="auto"
          variant="scrollable"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={tab.label}
              label={tab.label}
              {...a11yProps(label, index)}
            />
          ))}
        </Tabs>
      </Box>

      {tabs.map((tab, index) => (
        <TabPanel
          key={tab.label}
          label={label}
          value={currentTab}
          index={index}
        >
          {tab.content}
        </TabPanel>
      ))}
    </Stack>
  );
};

export default GenericTabs;
