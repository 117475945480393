import {
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BaseDialog from "../BaseDialog";
import { Booking, Event, GetBookingsParams } from "../../shared";
import { useBookings } from "../../api/hooks/airtable/useBookings";
import { formatCurrency, formatDayTime } from "../../utils";
import useEvents from "../../api/hooks/airtable/useEvents";
import Markdown from "../../components/Markdown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaceIcon from "@mui/icons-material/Place";
import Ticket from "./Ticket";
import CreateBookingButton, {
  CreateBookingButtonType,
} from "../../components/buttons/CreateBookingButton";
import DeleteBookingButton, {
  DeleteBookingButtonType,
} from "../../components/buttons/DeleteBookingButton";
import GroupIcon from "@mui/icons-material/Group";
import AttendeeListDialog from "../AttendeeListDialog";

interface IEventDialog {
  eventId: string | undefined;
  onClose: () => void;
}

const refreshBooking = (
  setBookings: (booking?: Booking[]) => void,
  getBookings: (
    requestData: GetBookingsParams
  ) => Promise<Booking[] | undefined>,
  eventId?: string
) => {
  if (!eventId) {
    setBookings(undefined);
    return;
  }

  getBookings({ eventId, onlyMyBookings: true }).then((bookings) => {
    setBookings(bookings);
  });
};

const refreshEvent = (
  setEvent: (event?: Event) => void,
  getEvent: (eventId: string) => Promise<Event[] | undefined>,
  eventId?: string
) => {
  if (!eventId) {
    setEvent(undefined);
    return;
  }

  getEvent(eventId).then((events) => {
    if (events && events.length > 0) {
      setEvent(events[0]);
    } else {
      setEvent(undefined);
    }
  });
};

const EventDialog: React.FC<IEventDialog> = ({ eventId, onClose }) => {
  const { getBookings, isLoadingGetBookings } = useBookings();

  const { isLoadingGetEvent, getEvent } = useEvents();

  const [bookings, setBookings] = useState<Booking[] | undefined>();
  const [event, setEvent] = useState<Event | undefined>();
  const [isAttendeeListOpen, setIsAttendeeListOpen] = useState(false);

  useEffect(() => {
    refreshBooking(setBookings, getBookings, eventId);
  }, [eventId, getBookings]);

  useEffect(() => {
    refreshEvent(setEvent, getEvent, eventId);
  }, [eventId, getEvent]);

  return (
    <>
      <BaseDialog
        open={!!eventId}
        onClose={onClose}
        title={event?.name || "Loading"}
        content={
          event && eventId && !isLoadingGetEvent ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <img
                    src={
                      event.image?.thumbnails?.large.url ||
                      event.venue.image.thumbnails?.large.url
                    }
                    alt="Event thumbnail"
                    style={{
                      aspectRatio: "4/3",
                      objectFit: "cover",
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={1}>
                    <AccessTimeIcon fontSize="small" />
                    <Typography variant="body2">
                      {formatDayTime(new Date(event.startTime))} to{" "}
                      {formatDayTime(new Date(event.endTime))}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <PlaceIcon fontSize="small" />
                    <Typography variant="body2">{event.venue.name}</Typography>
                  </Stack>

                  <Markdown raw={event.description} />

                  {isLoadingGetBookings ? (
                    <LinearProgress />
                  ) : (
                    <Stack spacing={2}>
                      {event.requiresSignUp && bookings && (
                        <Stack spacing={1}>
                          {bookings.map((booking) => (
                            <Ticket
                              // @ts-ignore
                              key={booking.id}
                              // @ts-ignore
                              bookingId={booking.id}
                              onDeleteSuccess={() => {
                                refreshBooking(
                                  setBookings,
                                  getBookings,
                                  eventId
                                );
                                refreshEvent(setEvent, getEvent, eventId);
                              }}
                            />
                          ))}
                        </Stack>
                      )}

                      <Stack direction="row" spacing={1} alignItems="center">
                        <Stack flex={1}>
                          {event.requiresSignUp ? (
                            <CreateBookingButton
                              eventId={eventId}
                              onCreateSuccess={() => {
                                refreshBooking(
                                  setBookings,
                                  getBookings,
                                  eventId
                                );
                                refreshEvent(setEvent, getEvent, eventId);
                              }}
                              text={`Buy ticket - ${formatCurrency(
                                event.price
                              )}`}
                              disabled={event.tickets.remaining === 0}
                              variant="contained"
                              type={CreateBookingButtonType.TICKET}
                            />
                          ) : bookings && bookings.length > 0 ? (
                            <DeleteBookingButton
                              // @ts-ignore
                              bookingId={bookings[0].id}
                              onDeleteSuccess={() => {
                                refreshBooking(
                                  setBookings,
                                  getBookings,
                                  eventId
                                );
                                refreshEvent(setEvent, getEvent, eventId);
                              }}
                              text="Remove from itinerary"
                              variant="outlined"
                              type={DeleteBookingButtonType.CALENDAR}
                            />
                          ) : (
                            <CreateBookingButton
                              eventId={eventId}
                              onCreateSuccess={() => {
                                refreshBooking(
                                  setBookings,
                                  getBookings,
                                  eventId
                                );
                                refreshEvent(setEvent, getEvent, eventId);
                              }}
                              text="Add to itinerary"
                              disabled={event.tickets.remaining === 0}
                              variant="contained"
                              type={CreateBookingButtonType.CALENDAR}
                            />
                          )}
                        </Stack>
                        <IconButton
                          aria-label="open attendees list"
                          onClick={() => setIsAttendeeListOpen(true)}
                        >
                          <GroupIcon />
                        </IconButton>
                      </Stack>

                      {event.requiresSignUp && (
                        <Typography variant="body2">
                          <b>Remaining tickets:</b> {event.tickets.remaining}
                        </Typography>
                      )}
                    </Stack>
                  )}
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <LinearProgress />
          )
        }
      />

      <AttendeeListDialog
        isOpen={isAttendeeListOpen}
        eventId={eventId}
        onClose={() => setIsAttendeeListOpen(false)}
      />
    </>
  );
};
export default EventDialog;
