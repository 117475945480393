import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

const StyledGenericButton = styled.button`
  display: block;
  padding: ${(props) => props.theme.measurements.mdSpacing}px;
  border-radius: 0;
  border: none;
  background-color: #${(props) => props.theme.palette.blue};
  font-size: 1em;
  color: #ffffff;
  cursor: pointer;
`;

export const GenericButton: React.FC<ButtonHTMLAttributes<any>> = ({
  children,
  ...props
}) => <StyledGenericButton {...props}>{children}</StyledGenericButton>;
