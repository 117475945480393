import React from "react";
import { PageWrapper } from "../components/PageWrapper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";

export const ErrorPage: React.FC = () => (
  <PageWrapper title="404 - Page not found">
    <Stack spacing={2} alignItems="flex-start">
      <Typography variant="body1">This page does not exist.</Typography>
      <Button
        startIcon={<HomeIcon />}
        variant="contained"
        component={Link}
        to="/"
      >
        Go home
      </Button>
    </Stack>
  </PageWrapper>
);
