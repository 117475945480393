import {
    Breakpoint,
    DialogContent,
    IconButton,
    Stack,
    SxProps,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import SlideUpTransition from "../transitions/SlideUpTransition";
import CloseIcon from "@mui/icons-material/Close";
import {useEffect} from "react";

interface IBaseDialog {
    open: boolean;
    onClose: () => void;
    title: string;
    bannerImageSrc?: string;
    content: React.ReactNode;
    paperSxOverride?: SxProps;
    maxWidth?: Breakpoint;
}

const BaseDialog: React.FC<IBaseDialog> = ({
                                               open,
                                               onClose,
                                               title,
                                               bannerImageSrc,
                                               content,
                                               paperSxOverride,
                                               maxWidth = "md",
                                           }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    // onModalOpen() {
    //   window.history.pushState(null, '', window.location.href)
    //   window.onpopstate = () => {
    //       this.onModalClose(true)
    //   }
    // }
    //
    //   onModalClose(fromNavigation) {
    //       if(!fromNavigation)
    //           window.history.back()
    //
    //       window.onpopstate = () => {
    //           // Do nothing
    //       }
    //   }

    const onCloseWrapper = (fromNavigation = false) => {
        if (!fromNavigation) window.history.back()

        window.onpopstate = () => {
        }
    }

    useEffect(() => {
        if (open) {
            window.history.pushState(null,'', window.location.href)
            window.onpopstate = () => onCloseWrapper(true)
        }
    }, [open])

    return (
        <Dialog
            fullScreen={isMobile}
            TransitionComponent={isMobile ? SlideUpTransition : undefined}
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={maxWidth}
            PaperProps={{
                sx: paperSxOverride,
            }}
        >
            <DialogTitle display="flex" justifyContent="space-between">
                {title}
                <IconButton onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            {bannerImageSrc && (
                <img
                    src={bannerImageSrc}
                    alt={`${title} banner`}
                    style={{width: "100%", height: 200, objectFit: "cover"}}
                />
            )}
            <DialogContent>
                <Stack gap={3}>{content}</Stack>
            </DialogContent>
        </Dialog>
    );
};

export default BaseDialog;
