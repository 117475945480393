import React from "react";
import styled from "styled-components";
import logo from "../../assets/logo.svg";

const StyledMobileLogoHeader = styled.nav`
  display: flex;
  height: ${(props) => props.theme.measurements.lgSpacing}px;
  padding: ${(props) => props.theme.measurements.lgSpacing}px;
  justify-content: center;
  z-index: 1001; // Weird z-index to get around Leaflet layers

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  background-color: white;

  // Desktop screens
  @media only screen and (min-width: ${(props) =>
      props.theme.screens.desktopMin}px) {
    display: none;
  }
`;

export const MobileLogoHeader: React.FC = () => (
  <StyledMobileLogoHeader>
    <img src={logo} alt="Logo" />
  </StyledMobileLogoHeader>
);
