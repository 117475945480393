import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const HelpPage1Events: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h5" component="h2">
        Events
      </Typography>
      <Typography variant="body1">
        The{" "}
        <Typography component="b" fontWeight="bold">
          Events
        </Typography>{" "}
        page lists all events at the festival. Click on any event to view its
        details and sign up.
      </Typography>
      <Typography variant="body1">
        There are both{" "}
        <Typography component="b" fontWeight="bold">
          ticketed
        </Typography>{" "}
        and{" "}
        <Typography component="b" fontWeight="bold">
          un-ticketed
        </Typography>{" "}
        events.
      </Typography>
      <Typography variant="body1">
        <Typography component="b" fontWeight="bold">
          Ticketed
        </Typography>{" "}
        events may cost money and/or have a maximum number of attendees.
      </Typography>
      <Typography variant="body1">
        <Typography component="b" fontWeight="bold">
          Un-ticketed
        </Typography>{" "}
        events are free and open to everyone.
      </Typography>
      <Typography variant="body1">
        Guests can sign up for events using their own account, or you can sign
        up on their behalf by purchasing multiple tickets.
      </Typography>
      <Typography variant="body1">
        Payments will be collected after the festival, based on the events you
        sign up for and attend.
      </Typography>
    </Stack>
  );
};

export default HelpPage1Events;
