import Typography from "@mui/material/Typography";
import BaseDialog from "./BaseDialog";
import SignInForm from "../components/forms/SignInForm";
import Alert from "@mui/material/Alert";

interface ISignInDialog {
  open: boolean;
  onClose: () => void;
}

const SignInDialog: React.FC<ISignInDialog> = ({ open, onClose }) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={"Sign in"}
      content={
        <>
          <Alert severity="warning">
            Before you can sign in, you'll need to be added to the guest list by
            an employee.
          </Alert>

          <Typography variant="body1">
            You'll receive an email link to sign in.
          </Typography>

          <SignInForm onClose={onClose} />
        </>
      }
    />
  );
};

export default SignInDialog;
