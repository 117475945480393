import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import enGbLocale from "@fullcalendar/core/locales/en-gb";
import React, { useContext } from "react";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { EventDialogContext } from "../contexts/eventDialogContext";
import { brand, getHexForEventType } from "../brand";
import { BookingEvent } from "../shared";
import "./EventsCalendar.css";
import { useColorMode } from "../hooks/useColorMode";

interface IEventsCalendar {
  bookingEvents: BookingEvent[];
}

const EventsCalendar: React.FC<IEventsCalendar> = ({ bookingEvents }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
  const { setEventId } = useContext(EventDialogContext);
  const { isDarkModeEnabled } = useColorMode();

  return (
    <FullCalendar
      plugins={[timeGridPlugin, listPlugin]}
      initialView={isDesktop ? "timeGridFourDay" : "listWeek"}
      views={{
        timeGridFourDay: {
          type: "timeGrid",
          duration: {
            days: isDesktop ? 2 : 1,
          },
          nowIndicator: true,
        },
      }}
      headerToolbar={{
        start: "listWeek,timeGridFourDay",
        end: "prev,next",
      }}
      buttonText={{
        listWeek: "List",
        timeGridFourDay: "Calendar",
      }}
      initialDate={"2023-09-14"}
      validRange={{
        start: "2023-09-14",
        end: "2023-09-18",
      }}
      events={bookingEvents?.map((evt) => ({
        id: evt.id,
        title: evt.name,
        start: evt.startTime,
        end: evt.endTime,
        borderColor: getHexForEventType(evt.type),
        extendedProps: {
          location: evt.venue.name,
        },
      }))}
      locale={enGbLocale}
      eventClick={(evt) => setEventId(evt.event.id)}
      eventBackgroundColor={isDarkModeEnabled ? brand.darkGrey : "#fff"}
      eventTextColor={isDarkModeEnabled ? "#fff" : brand.darkGrey}
      contentHeight="auto"
      slotMinTime="08:00:00"
    />
  );
};

export default EventsCalendar;
