import React from "react";
import DeleteBookingButton, {
  DeleteBookingButtonType,
} from "../../components/buttons/DeleteBookingButton";
import ListItem from "../../components/ListItem";

interface ITicket {
  bookingId: string;
  onDeleteSuccess: () => void;
}

const Ticket: React.FC<ITicket> = ({ bookingId, onDeleteSuccess }) => {
  return (
    <ListItem
      text={`Ticket: ${bookingId}`}
      rightButton={
        <DeleteBookingButton
          bookingId={bookingId}
          onDeleteSuccess={onDeleteSuccess}
          text={"Sell"}
          variant={"text"}
          type={DeleteBookingButtonType.TICKET}
        />
      }
    />
  );
};

export default Ticket;
