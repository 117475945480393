import React, { useEffect, useState } from "react";
import useEvents from "../../api/hooks/airtable/useEvents";
import { Event } from "../../shared";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import FeaturedEventCard from "./FeaturedEventCard";

interface IFeaturedEvents {}

const FeaturedEvents: React.FC<IFeaturedEvents> = () => {
  const { getFeaturedEvents, isLoadingGetFeaturedEvents } = useEvents();

  const [featuredEvents, setFeaturedEvents] = useState<Event[] | undefined>(
    undefined
  );
  useEffect(() => {
    getFeaturedEvents().then(setFeaturedEvents);
  }, [getFeaturedEvents]);

  return (
    <Stack spacing={2}>
      {isLoadingGetFeaturedEvents ? (
        <LinearProgress />
      ) : featuredEvents ? (
        featuredEvents.map((featuredEvent) => (
          <FeaturedEventCard
            key={featuredEvent.startTime + featuredEvent.name}
            event={featuredEvent}
          />
        ))
      ) : (
        <Typography>No featured events</Typography>
      )}
    </Stack>
  );
};

export default FeaturedEvents;
