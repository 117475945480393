import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { EventsPage } from "./pages/EventsPage";
import { ErrorPage } from "./pages/ErrorPage";
import { ProviderCollection } from "./contexts/providerCollection";
import { MapPage } from "./pages/MapPage";
import { AuthContext } from "./contexts/authContext";
import NavBar from "./components/navigation/NavBar";
import LoginPage from "./pages/LoginPage";
import SettingsPage from "./pages/SettingsPage";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

function Main() {
  const authContext = useContext(AuthContext);

  return (
    <Box color="text.primary" bgcolor="background.default" minHeight="100dvh">
      {authContext.isSignedIn ? (
        <>
          <Container>
            <Box paddingY={8}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/events" element={<EventsPage />} />
                <Route path="/map" element={<MapPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Box>
          </Container>
          <NavBar />
        </>
      ) : (
        <LoginPage />
      )}
    </Box>
  );
}

function App() {
  return (
    <ProviderCollection>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ProviderCollection>
  );
}

export default App;
