import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import useAuth from "../../api/hooks/firebase/useAuth";

interface ISignInForm {
  onClose: () => void;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .test(
      "is-not-softwire",
      (d) => `You can't use an @softwire.com email address`,
      (value) => !value?.endsWith("@softwire.com") || false
    )
    .required("This field is required"),
});

const SignInForm: React.FC<ISignInForm> = ({ onClose }) => {
  const { signInWithEmail } = useAuth();

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        signInWithEmail(values.email);
        onClose();
      } catch (err) {
        alert(err.message);
      }

      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack gap={3}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <LoadingButton
          color="secondary"
          variant="contained"
          type="submit"
          endIcon={<SendIcon />}
          loading={formik.isSubmitting}
          sx={{
            alignSelf: "flex-end",
          }}
        >
          Sign in
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default SignInForm;
