import React from "react";
import BaseDialog from "./BaseDialog";
import HelpPages from "../components/helpPages/HelpPages";

interface IOnboardingDialog {
  isOpen: boolean;
  onClose: () => void;
}

const HelpDialog: React.FC<IOnboardingDialog> = ({ isOpen, onClose }) => {
  return (
    <BaseDialog
      open={isOpen}
      onClose={onClose}
      title={"Get started"}
      content={<HelpPages />}
    />
  );
};

export default HelpDialog;
