import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const HelpPage3Home: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h5" component="h2">
        Home
      </Typography>
      <Typography variant="body1">
        The{" "}
        <Typography component="b" fontWeight="bold">
          Home
        </Typography>{" "}
        page lets you see the next events coming up on your calendar. Any events
        you've added will show up here.
      </Typography>
    </Stack>
  );
};

export default HelpPage3Home;
