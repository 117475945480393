import { Link, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import React from "react";

interface IMarkdown {
  raw: string;
}

const Markdown: React.FC<IMarkdown> = ({ raw }) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ children }) => (
          <Typography variant={"body2"} marginTop={1}>
            {children}
          </Typography>
        ),
        h1: ({ children }) => (
          <Typography variant={"h4"} component={"h1"} marginTop={2}>
            {children}
          </Typography>
        ),
        h2: ({ children }) => (
          <Typography variant={"h5"} component={"h2"} marginTop={2}>
            {children}
          </Typography>
        ),
        h3: ({ children }) => (
          <Typography variant={"h6"} component={"h3"} marginTop={2}>
            {children}
          </Typography>
        ),
        h4: ({ children }) => (
          <Typography variant={"h6"} component={"h4"} marginTop={2}>
            {children}
          </Typography>
        ),
        h5: ({ children }) => (
          <Typography variant={"h6"} component={"h5"} marginTop={2}>
            {children}
          </Typography>
        ),
        h6: ({ children }) => (
          <Typography variant={"h6"} component={"h6"} marginTop={2}>
            {children}
          </Typography>
        ),
        li: ({ children }) => (
          <Typography variant={"body2"} component={"li"}>
            {children}
          </Typography>
        ),
        ul: ({ children }) => (
          <ul style={{ listStylePosition: "inside" }}>{children}</ul>
        ),
        a: ({ children, ...props }) => (
          <Typography
            variant="body2"
            {...props}
            target="_blank"
            component={Link}
          >
            {children}
          </Typography>
        ),
      }}
    >
      {raw}
    </ReactMarkdown>
  );
};

export default Markdown;
